export interface MenuItem {
  label: string;
  path?: string;
  children?: MenuItem[];
  target?: string;
}

export const navigationConfig: MenuItem[] = [
  {
    label: 'Gurudev',
    path: '/us-en/gurudev',
  },
  {
    label: 'Explore',
    children: [
      {
        label: 'Breathwork',
        path: 'https://event.us.artofliving.org/us-en/breathwork2/lp1/',
      },
      {
        label: 'Meditation',
        path: 'https://event.us.artofliving.org/us-en/secrets-of-meditation2/lp1/',
      },
    ],
  },
  {
    label: 'Meditation',
    children: [
      {
        label: 'Daily Practices',
        path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/daily-sky`,
      },
      {
        label: 'Guided Meditation',
        path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/guided-meditation/44s1gKjna5dNhzrn3vTdBq`,
      },
      {
        label: 'Meetups',
        path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/meetup`,
      },
    ],
  },
  {
    label: 'Wisdom',
    children: [
      {
        label: 'Podcasts',
        path: '/us-en/wisdom/podcast',
      },
    ],
  },
  {
    label: 'Courses',
    children: [
      {
        label: "Beginner's Courses",
        children: [
          {
            label: 'Art Of Living Part 1',
            path: 'https://event.us.artofliving.org/us-en/artoflivingpart1',
          },
          {
            label: 'Sahaj Samadhi Meditation',
            path: 'https://event.us.artofliving.org/us-en/sahajsamadhi/',
          },
          {
            label: 'Art Of Living Premium',
            path: '/us-en/premiumcourse',
          },
          {
            label: 'Sri Sri Yoga Foundation',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/online-foundation-program?utm_source=organic&utm_medium=home&utm_content=menu&course_id=1004431`,
          },
          {
            label: 'Sleep and Anxiety Protocol',
            path: 'https://event.us.artofliving.org/us-en/lp1/sleep-anxiety-protocol/',
          },
        ],
      },
      {
        label: 'Advanced Courses',
        children: [
          {
            label: 'Art Of Living Part 2',
            path: 'https://event.us.artofliving.org/us-en/artoflivingpart2',
          },
          {
            label: 'Blessings',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/blessings-course`,
          },
          {
            label: 'Chakra Kriya',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/chakra-kriya`,
          },
          {
            label: 'DSN',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/courses/dsn-course`,
          },
          {
            label: 'Sanyam',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/sanyam`,
          },
          {
            label: 'Shakti Kriya',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/shakti-kriya`,
          },
          {
            label: 'Sri Sri Yoga Deep Dive',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/srisriyoga-deepdiveretreat`,
          },
        ],
      },
      {
        label: 'Training Courses',
        children: [
          {
            label: 'Volunteer Training',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/vtp`,
          },
          {
            label: 'Teacher Training',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/teacher-training-course`,
          },
          {
            label: 'Sri Sri Yoga Teacher Training',
            path: 'https://artoflivingretreatcenter.org/event/sri-sri-school-of-yoga-ttc',
          },
          {
            label: 'Sri Sri Marma Practitioner',
            path: 'https://event.us.artofliving.org/us-en/marma-training',
          },
          {
            label: 'All Courses',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/courses`,
          },
          {
            label: 'Help me choose',
            path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/course-finder/welcome`,
          },
        ],
      },
    ],
  },
  {
    label: 'Events',
    children: [
      {
        label: 'Boone, NC',
        path: 'https://event.us.artofliving.org/lp1/yoga-sutras-gurudev/',
        target: '_blank',
      },
      {
        label: 'Mobile, AL',
        path: 'https://event.us.artofliving.org/us-en/lp1/journey-within-alabama/',
        target: '_blank',
      },
    ],
  },
  {
    label: 'Centers',
    children: [
      {
        label: 'Art of Living Boone Retreat',
        path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/theartoflivingretreatcenter`,
      },
      {
        label: 'Connect Locally',
        path: '/us-en/centers',
      },
      {
        label: 'Los Angeles',
        path: 'https://artoflivingla.org/',
      },
      {
        label: 'Washington DC',
        path: 'https://dc.artofliving.org/',
      },
    ],
  },
  {
    label: 'Resources',
    children: [
      {
        label: 'Meditations',
        path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/library/44s1gKjna5dNhzrn3vTdBq`,
      },
      {
        label: 'Wisdom',
        path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/library/4BwEBABbNz7YlDQGZoTPPE`,
      },
      {
        label: 'App',
        path: `${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/journey-app`,
      },
      {
        label: 'Blog',
        path: 'https://www.artofliving.org/us-en/sri-sri-blog',
      },
      {
        label: 'Science',
        path: '/us-en/meditation/benefits/research-sudarshan-kriya',
      },
    ],
  },
  {
    label: 'About',
    children: [
      {
        label: 'Art of Living',
        path: '/us-en/about-us',
      },
      {
        label: 'Service Projects',
        path: 'https://www.artofliving.org/us-en/service-projects-overview',
      },
      {
        label: 'World Culture Festival',
        path: 'https://wcf.artofliving.org/',
      },
      {
        label: 'Press & Media',
        path: 'https://www.artofliving.org/us-en/media-coverage?search=',
      },
      {
        label: 'Contact Us',
        path: 'https://www.artofliving.org/us-en/contact-us',
      },
    ],
  },
];
