import(/* webpackMode: "eager" */ "/tmp/build_86e9f3d7/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-lora\",\"display\":\"swap\",\"style\":[\"italic\",\"normal\"]}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/tmp/build_86e9f3d7/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-work-sans\",\"display\":\"swap\"}],\"variableName\":\"work_sans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_86e9f3d7/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_86e9f3d7/src/components/Header.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_86e9f3d7/src/contexts/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_86e9f3d7/src/providers/TanstackProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_86e9f3d7/src/styles/globals.scss");
